import { gql, useMutation } from "@apollo/client";
import { FOLLOW_CATEGORY_MUTATION } from "graphql/mutations";

export function useFollowCategory() {
  const [followCategoryMutation] = useMutation(FOLLOW_CATEGORY_MUTATION);

  return ({ variables }: any) => {
    const { slug, isFollowing } = variables;
    return followCategoryMutation({
      variables: {
        slug,
        isFollowing,
      },
      optimisticResponse: {
        __typename: "Mutation",
        followCategory: {
          slug,
          isFollowing,
        },
      },
      update: (cache, { data: { followCategory } }) => {
        cache.writeFragment({
          id: `Category:{"slug":"${slug}"}`,
          fragment: gql`
            fragment MyCategory on Category {
              isFollowing
            }
          `,
          data: {
            isFollowing: followCategory?.isFollowing,
          },
        });
      },
    });
  };
}
